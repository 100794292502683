import { Houseboat } from '@mui/icons-material'
import React from 'react'
import { PATH_MOORINGPLAN } from '../../constants'

export const mooringPlanNavs = [
  {
    name: 'Mooring plan',
    page: React.createElement(React.lazy(() => import('./MooringPlanMap'))),
    menu: {
      icon: <Houseboat />,
    },
    path: PATH_MOORINGPLAN,
  },
]
