import { MoreVertical as KebabMenuIcon } from '@griegconnect/krakentools-react-icons'
import { Box, ListItemIcon, ListItemText, Typography } from '@mui/material'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Fade from '@mui/material/Fade'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Popper, { PopperPlacementType } from '@mui/material/Popper'
import React, { ReactElement } from 'react'
import MapButton from './MapButton'
import { MapTranslucentPaper } from './MapTranslucentPaper'

export type MapMenuItem = {
  name: string
  /**
   * This will only display the hotkey in the menu
   * You need to setup the keyboard event yourself.
   */
  hotkey?: string
  icon?: ReactElement
  disabled?: boolean
  callback: () => void
}

type MapMenuProps = {
  items: MapMenuItem[]
  placement?: PopperPlacementType
  top?: number
}

export const MapMenu = ({ items, placement = 'bottom-end', top = 24 }: MapMenuProps) => {
  const anchorRef = React.useRef<HTMLButtonElement>(null)
  const [open, setOpen] = React.useState(false)

  const openPopper = (newState: boolean) => (_event: React.MouseEvent<HTMLButtonElement> | TouchEvent | MouseEvent) =>
    setOpen(newState)

  return (
    <>
      <MapButton onClick={openPopper(!open)} icon={<KebabMenuIcon />} ref={anchorRef} />
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role="menu"
        transition
        sx={{ zIndex: 1000 }}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, top],
            },
          },
          {
            name: 'preventOverflow',
            options: {
              mainAxis: false,
            },
          },
        ]}
        placement={placement}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <MapTranslucentPaper>
              <ClickAwayListener onClickAway={openPopper(false)}>
                <MenuList autoFocusItem={open}>
                  {items.map((action) => (
                    <MenuItem
                      key={action.name}
                      role="menuitem"
                      disabled={action.disabled}
                      onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
                        event.stopPropagation()
                        setTimeout(() => {
                          action.callback()
                          setOpen(false)
                        }, 300)
                      }}
                    >
                      {action.icon && <ListItemIcon>{action.icon}</ListItemIcon>}
                      <ListItemText>{action.name} </ListItemText>
                      {action.hotkey && <Typography sx={{ color: 'text.hint', ml: 2 }}>{action.hotkey}</Typography>}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </MapTranslucentPaper>
          </Fade>
        )}
      </Popper>
    </>
  )
}

export default MapMenu
