import { Tenant } from '@griegconnect/krakentools-react-kraken-app'

export type AccessDependencies = {
  activeTenant: Tenant.Type
}

export namespace Permissions {
  type Domain = 'monitor' | 'port'
  export type Permission = { domain: Domain; name: string }
  type Read = { read: Permission }
  type Write = { write: Permission }

  export const all = (p: { [k: string]: Permission }): Permission[] => Object.values(p)
  export const _write = (domain: Domain, name: string): Write => ({ write: { domain, name: `${name}:write` } })
  export const _read = (domain: Domain, name: string): Read => ({ read: { domain, name: `${name}:read` } })
  export const _rw = (domain: Domain, name: string) => ({
    ...Permissions._write(domain, name),
    ...Permissions._read(domain, name),
  })
}

export const PERMISSIONS = {
  activities: { ...Permissions._rw('monitor', 'activities') },
  layers: { ...Permissions._rw('monitor', 'layers') },
  maps: { ...Permissions._rw('monitor', 'maps') },
  mapview: { ...Permissions._rw('monitor', 'mapview') },
  portcalls: { ...Permissions._read('monitor', 'portcalls') },
  shared: { ...Permissions._write('monitor', 'shared') },
  vessellist: { ...Permissions._rw('monitor', 'vessellist') },
  app: { 
    port: {
      domain: 'port',
      name: 'app:port'
    }
   },
} as const satisfies Record<string, Record<string, Permissions.Permission>>
