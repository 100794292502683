import { useConfig, useHttp, useTenants } from '@griegconnect/krakentools-react-kraken-app'
import React, { useContext, useEffect, useState } from 'react'
import { MonitorApi, SearchitApi } from '@griegconnect/krakentools-react-kmap'
import { WhereApi } from '@griegconnect/krakentools-react-kmap'
import ActivityApi from './apis/ActivityApi'
import GeofenceApi from './apis/GeofenceApi'
import NotificationListApi from './apis/NotificationListApi'
import PortApi from './apis/PortApi'

type IApis = {
  activityApi: ActivityApi
  geofenceApi: GeofenceApi
  monitorApi: MonitorApi
  notificationListApi: NotificationListApi
  searchApi: SearchitApi
  whereApi: WhereApi
  portApi: PortApi
}

export const ApisContext = React.createContext<IApis>({} as IApis)
export const useApis = () => useContext(ApisContext)!

export const ApiProvider = (props: { children: React.ReactNode }) => {
  const [apis, setApis] = useState<IApis | null>(null)
  const config = useConfig()
  const { activeTenant } = useTenants()
  const { httpClient: axiosClient } = useHttp()

  useEffect(() => {
    if (activeTenant) {
      const activityApi = new ActivityApi(axiosClient(`${config.api.monitorApiBaseUrl}/tenants/${activeTenant.id}`))
      const geofenceApi = new GeofenceApi(axiosClient(`${config.api.monitorApiBaseUrl}/tenants/${activeTenant.id}`))
      const monitorApi = new MonitorApi(axiosClient(`${config.api.monitorApiBaseUrl}/tenants/${activeTenant.id}`))
      const notificationListApi = new NotificationListApi(
        axiosClient(`${config.api.monitorApiBaseUrl}/tenants/${activeTenant.id}`)
      )
      const searchApi = new SearchitApi(axiosClient(`${config.api.searchApiBaseUrl}/tenants/${activeTenant.id}`))
      const whereApi = new WhereApi(axiosClient(`https://${config.api.liveApiBaseUrl}/tenants/${activeTenant.id}`))
      const portApi = new PortApi(axiosClient(`${config.api.portApiBaseUrl}/companies/${activeTenant.ref}`))
      setApis({
        activityApi,
        geofenceApi,
        monitorApi,
        notificationListApi,
        searchApi,
        whereApi,
        portApi,
      })
    }
  }, [activeTenant]) // eslint-disable-line react-hooks/exhaustive-deps

  return apis ? <ApisContext.Provider value={{ ...apis }}>{props.children}</ApisContext.Provider> : null
}

export default ApiProvider
