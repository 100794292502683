import { Compass } from '@griegconnect/krakentools-react-icons'
import { Box } from '@mui/material'
import { createRef, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { useMapContext } from '../MapContext'
import { mapRotationSelector } from '../atoms/mapConfigAtoms'
import MapButton from '../shared/MapButton'
import { MapButtonPanelRegion } from '../shared/MapButtonPanel'
import { useTranslation } from 'react-i18next'

type RotateControlProps = {
  bottom?: number
  region?: MapButtonPanelRegion
  align?: string
  hideUnrotated?: boolean
}

export const RotateControl = (props: RotateControlProps) => {
  const [isRotated, setIsRotated] = useState(false)
  const [isInited, setIsInited] = useState(false)
  const { kmapInstance, mapIdentifierSlug } = useMapContext()
  const ref = createRef<SVGSVGElement>()
  const { t } = useTranslation('kmap')
  const rotation = useRecoilValue(mapRotationSelector(mapIdentifierSlug))
  const resetRotationHandler = () => {
    kmapInstance.getView().animate({ rotation: 0 })
  }

  // Set the initial state
  useEffect(() => {
    if (ref.current && !isInited) {
      ref.current.style.transform = `rotate(${rotation}deg)`
      setIsRotated(rotation !== 0)
      setIsInited(true)
    }
  }, [ref])

  // Update the state when the rotation changes
  useEffect(() => {
    const checkIsRotated = (rad: number) => {
      const shouldBeRotated = rad !== 0
      if (shouldBeRotated !== isRotated) {
        // Delay animation to avoid lagging UI
        setTimeout(() => {
          setIsRotated(shouldBeRotated)
        }, 200)
      }
    }

    const key = kmapInstance.on('rotate', (rad: number) => {
      checkIsRotated(rad)
      if (ref.current) {
        ref.current.style.transform = `rotate(${(rad * 180) / Math.PI}deg)`
      }
    })

    return () => {
      kmapInstance.un(key)
    }
  }, [ref])

  if (props.hideUnrotated && !isRotated) return null

  return (
    <MapButton
      isActive={false}
      toolTipText={t('RotateControl.buttonToolTip')}
      toolTipPlacement="left"
      icon={
        <Box style={{ height: 24, width: 24, fontSize: '1.5rem' }} ref={ref}>
          <Compass sx={{ height: 32, width: 32, marginTop: '-4px', marginLeft: '-4px' }} />
        </Box>
      }
      onClick={resetRotationHandler}
    />
  )
}
