import React from 'react'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { headerTitleAtom, HeaderTitleAtomType } from '../atoms/headerTitleAtom'

export const useTopBarTitle = () => {
  const [topBarTitleState, setTopBarTitleState] = useRecoilState(headerTitleAtom)
  const resetState = useResetRecoilState(headerTitleAtom)

  const setTopBarTitleComponent = (component?: HeaderTitleAtomType['component']) => {
    setTopBarTitleState((curState) => ({ ...curState, component }))
  }

  const setTopBarTitle = (title?: HeaderTitleAtomType['title']) => {
    setTopBarTitleState((curState) => ({ ...curState, title }))
  }

  const resetTopBarTitleState = () => {
    resetState()
  }

  return { topBarTitleState, setTopBarTitleComponent, setTopBarTitle, resetTopBarTitleState }
}
