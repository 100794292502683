import { Map as MapIcon } from '@griegconnect/krakentools-react-icons'
import { Navigation, requiresAllOfPermissions } from '@griegconnect/krakentools-react-kraken-app'
import React from 'react'
import { AccessDependencies, PERMISSIONS } from '@app/permissions'
import { tenantsUrlMatchPathPrefix } from '../../constants'

export const PATH_LIVE_MAP = tenantsUrlMatchPathPrefix + '/map'

export const liveMapNavs: Navigation.Item<AccessDependencies>[] = [
  {
    name: 'Map',
    page: React.createElement(React.lazy(() => import('./Map'))),
    menu: {
      icon: <MapIcon />,
    },
    search: { mode: 'live' },
    access: (d) => requiresAllOfPermissions([PERMISSIONS.layers.read, PERMISSIONS.maps.read])(d),
    path: PATH_LIVE_MAP,
  },
]
