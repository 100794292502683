import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgSignIn = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M11 19h8V5h-8V3h8c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2h-8v-2Z" />,
    <path d="M9.293 7.293a1 1 0 0 1 1.414 0l4 4a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414-1.414L11.586 13H3a1 1 0 1 1 0-2h8.586L9.293 8.707a1 1 0 0 1 0-1.414Z" />
  )
export default SvgSignIn
