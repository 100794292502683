import { Navigation, useConfig, useTenants } from '@griegconnect/krakentools-react-kraken-app'
import { navigation } from './modules/navs'

const MonitorProvider = () => {
  const { activeTenant } = useTenants()
  const { environment } = useConfig()

  return <Navigation navigation={navigation(environment)} dependencies={{ activeTenant: activeTenant! }} />
}

export default MonitorProvider
