import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgGeofence = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path
      fillRule="evenodd"
      d="M20.5 5.792a2.5 2.5 0 1 0-3.463-2.724L6.49 4.986a2.5 2.5 0 1 0-3.035 3.786l-1.4 10.268a2.5 2.5 0 1 0 2.756 3.419l12.702-2.443a2.5 2.5 0 1 0 2.988-3.808V5.792Zm-2 0a2.509 2.509 0 0 1-.99-.778l-2.607.475L18.5 9.086V5.792Zm-.963 11.16L7.433 6.846l-.47.085A2.5 2.5 0 0 1 5.95 8.536l9.756 9.757-.041.041 1.37-.263c.073-.418.249-.8.501-1.12Zm-12.776 3.48 3.502-.674-3.81-3.517-.445 3.265c.32.242.58.56.753.925ZM19.5 4.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm-15 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm-1 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm16-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM9.826 6.412l2.684-.488 5.99 5.99v3.172L9.826 6.412Zm-5.043 7.412.435-3.191 8.145 8.144-2.716.523.031-.035-5.895-5.441Z"
      clipRule="evenodd"
    />
  )
export default SvgGeofence
