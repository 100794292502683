import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgAisVessel = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M3.03 9.574a3 3 0 0 1 1.34-2.093A9.002 9.002 0 0 1 4 4.921c0-1.448.342-2.815.948-4.026L3.16 0A10.954 10.954 0 0 0 2 4.92c0 1.664.37 3.24 1.03 4.654ZM6.314 7h2.138A4.982 4.982 0 0 1 8 4.92c0-.804.19-1.563.527-2.236l-1.79-.895A6.97 6.97 0 0 0 6 4.92c0 .725.11 1.423.314 2.08ZM20.97 9.574a3 3 0 0 0-1.34-2.093c.24-.811.37-1.67.37-2.56a8.962 8.962 0 0 0-.948-4.026L20.84 0A10.955 10.955 0 0 1 22 4.92c0 1.664-.37 3.24-1.03 4.654ZM18 4.92c0 .725-.11 1.423-.314 2.08h-2.138c.29-.633.452-1.338.452-2.08 0-.804-.19-1.563-.527-2.236l1.79-.895A6.97 6.97 0 0 1 18 4.92Z" />,
    <path d="M14 4.92a2 2 0 0 1-1 1.733V9h5a1 1 0 0 1 1 1v2.28l2.316.771a1 1 0 0 1 .665 1.145l-.754 3.77a6.938 6.938 0 0 1-1.95-.454l.57-2.843L12 12.054 4.154 14.67l.568 2.843a6.939 6.939 0 0 1-1.949.453l-.754-3.769a1 1 0 0 1 .665-1.145L5 12.28V10a1 1 0 0 1 1-1h5V6.653a2 2 0 1 1 3-1.733Z" />,
    <path d="M8.16 18.013a1 1 0 0 1 .744.554c.017.03.05.08.1.145.098.132.26.318.496.507.46.368 1.243.781 2.5.781s2.04-.413 2.5-.78c.237-.19.398-.376.497-.508a1.85 1.85 0 0 0 .114-.17 1 1 0 0 1 1.591-.254l.005.005.045.04c.045.04.12.102.223.18.207.155.532.372.972.593.878.438 2.226.894 4.053.894a1 1 0 1 1 0 2c-2.173 0-3.825-.544-4.947-1.106a8.81 8.81 0 0 1-.874-.5 4.986 4.986 0 0 1-.43.387C14.96 21.413 13.744 22 12 22c-1.743 0-2.96-.587-3.75-1.22a5.003 5.003 0 0 1-.43-.385 8.81 8.81 0 0 1-.873.5C5.825 21.454 4.173 22 2 22a1 1 0 1 1 0-2c1.827 0 3.175-.456 4.053-.894.44-.22.764-.438.972-.594a3.849 3.849 0 0 0 .268-.22l.005-.004a1 1 0 0 1 .862-.275Z" />
  )
export default SvgAisVessel
