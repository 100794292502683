import * as React from 'react'
import Button from '@mui/material/Button'
import ButtonGroup, { ButtonGroupOwnProps } from '@mui/material/ButtonGroup'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import { ArrowDown } from '@griegconnect/krakentools-react-icons'

type SplitButtonOption = {
  text: string
  onClick: () => void
}

type SplitButtonProps = {
  options: SplitButtonOption[]
  variant?: ButtonGroupOwnProps['variant']
  flipArrow?: true
}

const SplitButton = (props: SplitButtonProps) => {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }
    setOpen(false)
  }

  return (
    <React.Fragment>
      <ButtonGroup variant={props.variant || 'contained'} ref={anchorRef}>
        <Button
          fullWidth
          onClick={() => {
            setOpen(false)
            props.options[0].onClick()
          }}
        >
          {props.options[0].text}
        </Button>
        {props.options.length > 1 && (
          <Button size="small" aria-haspopup="menu" onClick={handleToggle}>
            <ArrowDown
              sx={{
                transform: (props.flipArrow && !open) || (open && !props.flipArrow) ? 'rotate(180deg)' : undefined,
              }}
            />
          </Button>
        )}
      </ButtonGroup>
      {props.options.length > 1 && (
        <Popper
          sx={{
            zIndex: (theme) => theme.zIndex.drawer,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper sx={{ width: anchorRef.current?.clientWidth || 0 }} elevation={12}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem>
                    {props.options.map((option, index) => {
                      if (index === 0) return null
                      return (
                        <MenuItem
                          key={'option_' + option.text}
                          onClick={() => {
                            setOpen(false)
                            option.onClick()
                          }}
                        >
                          {option.text}
                        </MenuItem>
                      )
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </React.Fragment>
  )
}

export default SplitButton
