import { AxiosInstance } from '@griegconnect/krakentools-react-kraken-app'
import { Geofence } from './types/Geofence'

type GeoFencesGetBaseArgs<T = {}> = {
  signal?: AbortSignal
} & T

type GeoFencesArgs = GeoFencesGetBaseArgs<{
  filter?: string
  archived?: boolean
  /**
   * default: all
   */
  type?: 'all' | 'parents'
}>

export default class GeofenceApi {
  private httpClient: AxiosInstance

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient
  }

  async geofences(args: GeoFencesArgs) {
    const { filter, signal, archived, type } = args
    const params = new URLSearchParams()
    params.set('archived', archived ? 'true' : 'false')
    const resp = await this.httpClient.get<Geofence.Data[]>(`geofences?${params.toString()}`, { signal })
    const geofences: Geofence.Data[] =
      type && type === 'parents' ? resp.data.filter((geofence) => geofence.parentId === null) : resp.data
    if (filter) {
      return geofences.filter((geofence) => geofence.name.toLowerCase().includes(filter.toLowerCase()))
    }
    return geofences
  }

  async update(geofence: Geofence.Form) {
    return await this.httpClient.put('geofences/' + geofence.id, geofence)
  }

  async create(geofence: Geofence.Form) {
    return await this.httpClient.post('geofences', geofence)
  }

  async archive(geofenceId: Geofence.Id) {
    return await this.httpClient.delete('geofences/' + geofenceId)
  }

  async notifications({ geofenceId, signal }: GeoFencesGetBaseArgs<{ geofenceId: Geofence.Id }>) {
    const resp = await this.httpClient.get<Geofence.Notifications[]>('geofences/' + geofenceId + '/notifications', {
      signal,
    })
    const withIndexes: Geofence.NotificationsWithIndex[] = resp.data.map((notification, index) => ({
      index,
      data: notification,
    }))
    return withIndexes
  }

  async upsertNotifications({ geofenceId, form }: { geofenceId: Geofence.Id; form: Geofence.NotificationsForm }) {
    return await this.httpClient.post('geofences/' + geofenceId + '/notifications', form)
  }

  async labels({ signal }: GeoFencesGetBaseArgs) {
    const resp = await this.httpClient.get<Geofence.Label[]>('geofences/labels', { signal })
    return resp.data
  }
}
