import { AxiosInstance } from '@griegconnect/krakentools-react-kraken-app'
import { Activity } from './types/Activity'

export type GetActivitiesOrderBy = 'eventTime' | 'processingTime'

type GetActivitiesRequestParams = {
  offset?: number
  limit?: number
  resolve?: boolean
  from?: string
  to?: string
  geofence?: string
  mmsi?: string
  orderBy?: GetActivitiesOrderBy
  tentative?: string
  type?: string
  labels?: string[]
  signal?: AbortSignal
}

type GetGroupedActivitiesRequestParams = {
  offset?: number
  limit?: number
  resolve?: boolean
  from?: string
  to?: string
  geofence?: string
  mmsi?: string
  labels?: string[]
  signal?: AbortSignal
}

export default class ActivityApi {
  private httpClient: AxiosInstance

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient
  }

  async activities({ offset, limit, from, to, geofence, mmsi, tentative, type, labels, resolve = true, signal }: GetActivitiesRequestParams) {
    const params = new URLSearchParams()
    if (offset !== undefined) params.set('offset', offset.toString())
    if (limit !== undefined) params.set('limit', limit.toString())
    if (resolve) params.set('resolve', resolve.toString())
    if (from !== undefined) params.set('from', from)
    if (to !== undefined) params.set('to', to)
    if (geofence !== undefined) params.set('geofence', geofence)
    if (mmsi !== undefined) params.set('mmsi', mmsi)
    if (tentative !== undefined) params.set('tentative', tentative)
    if (type !== undefined) params.set('type', type)
    if (labels !== undefined) {
      for (const label of labels) {
        params.append('labels', label)
      }
    }

    const resp = await this.httpClient.get<Activity.Data[]>(`activities?${params.toString()}`, { signal })
    return resp.data
  }
  
  async activity(id: Activity.Id, signal?: AbortSignal) {
    const resp = await this.httpClient.get<Activity.DetailedData>(`activities/${id}`, { signal })
    return resp.data
  }

  async groupedActivities({ offset, limit, from, to, geofence, mmsi, labels, resolve = true, signal }: GetGroupedActivitiesRequestParams): Promise<Activity.TreeData[]> {
    const params = new URLSearchParams()
    if (offset !== undefined) params.set('offset', offset.toString())
    if (limit !== undefined) params.set('limit', limit.toString())
    if (resolve) params.set('resolve', resolve.toString())
    if (from !== undefined) params.set('from', from)
    if (to !== undefined) params.set('to', to)
    if (geofence !== undefined) params.set('geofence', geofence)
    if (mmsi !== undefined) params.set('mmsi', mmsi)
    if (labels !== undefined) {
      for (const label of labels) {
        params.append('labels', label)
      }
    }

    const resp = await this.httpClient.get<Activity.GroupedData[]>(`activities/grouped?${params.toString()}`, { signal })
    const treeData: Activity.TreeData[] = []
    
    for (const {children, ...activity} of resp.data) {
      const treeItem: Activity.TreeData = {
        ...activity,
        path: [activity.id]
      }
      treeData.push(treeItem)

      for (const childActivty of children) {
        const childTreeItem: Activity.TreeData = {
          ...childActivty,
          path: [activity.id, childActivty.id]
        }
        treeData.push(childTreeItem)
      }
    }

    return treeData
  }
}
