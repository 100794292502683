import { AxiosInstance } from '@griegconnect/krakentools-react-kraken-app'
import { PortPhysicalObject } from './types/PortPhysicalObject'

export const PORT_QUAYS_QUERY_KEY = 'Port_quays'
export const PORT_AREAS_QUERY_KEY = 'Port_areas'
export default class PortApi {
  private httpClient: AxiosInstance

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient
  }

  async getQuays(abortSignal?: AbortSignal): Promise<PortPhysicalObject[]> {
    return await this.httpClient
      .get('/physicalobjects/groups/Quay')
      .then((response) => response.data.items)
  }

  async getAreas(abortSignal?: AbortSignal): Promise<PortPhysicalObject[]> {
    return await this.httpClient
      .get('/physicalobjects/groups/Area?MustHaveLocationCode=true')
      .then((response) => response.data.items)
  }
}
