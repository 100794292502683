import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgSignOut = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M13 5H5v14h8v2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h8v2Z" />,
    <path d="M16.293 7.293a1 1 0 0 1 1.414 0l4 4a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414-1.414L18.586 13H10a1 1 0 1 1 0-2h8.586l-2.293-2.293a1 1 0 0 1 0-1.414Z" />
  )
export default SvgSignOut
