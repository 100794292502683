import { useEffect } from 'react'
import { useMemo } from 'react'
import { useLayerContext } from '../LayerProvider'
import { assertIsStyle, IFeatureStyle, transformStyle } from '@griegconnect/krakentools-kmap'
import { LineString, Point, Polygon } from 'ol/geom'
import olFeature from 'ol/Feature'
import { useMapContext } from '../MapContext'
import { Feature as GeoJSONFeature } from 'geojson'

type Coordinates = number[] | number[][] | number[][][]

type FitOptions = {
  padding: number[]
  duration: number
}

export type FeatureProps = {
  id: string
  style?: IFeatureStyle
  enableSnap?: boolean
  coordinates: Coordinates
  properties?: GeoJSONFeature['properties']
  zIndex?: number
  /**
   * Indicates whether the map should zoom to this feature when it's rendered.
   */
  zoomTo?: boolean
  zoomOptions?: FitOptions
}

export const Feature: React.FC<FeatureProps> = ({
  id,
  coordinates,
  style,
  properties,
  enableSnap,
  zIndex,
  zoomTo,
  zoomOptions,
}) => {
  const { layer } = useLayerContext()
  const { kmapInstance } = useMapContext()
  const coordinatesToGeometry = (coordinates: Coordinates) => {
    let geometry
    if (Array.isArray(coordinates[0])) {
      if (Array.isArray(coordinates[0][0])) {
        geometry = new Polygon(coordinates as number[][][])
      } else {
        geometry = new LineString(coordinates as number[][])
      }
    } else {
      geometry = new Point(coordinates as number[])
    }
    geometry.transform('EPSG:4326', 'EPSG:3857')
    return geometry
  }

  const createFeature = (
    id: string,
    coordinates: number[] | number[][] | number[][][],
    style?: IFeatureStyle,
    properties?: GeoJSONFeature['properties'],
    zIndex?: number
  ) => {
    const feature = new olFeature(coordinatesToGeometry(coordinates))
    if (style) {
      const transformedStyle = assertIsStyle(transformStyle(style))
      if (zIndex) {
        transformedStyle.setZIndex(zIndex)
      }
      feature.setStyle(transformedStyle)
    }
    if (properties) {
      feature.setProperties(properties)
    }
    feature.set('id', id)
    feature.setId(id)
    feature.set('zIndex', zIndex)
    return feature
  }

  const feature = useMemo(() => {
    return createFeature(id, coordinates, style, properties, zIndex)
  }, [id, style])

  useEffect(() => {
    feature.setGeometry(coordinatesToGeometry(coordinates))
  }, [coordinates])

  const zoomToFeature = (feature: olFeature) => {
    const geom = feature.getGeometry()
    if (geom) {
      setTimeout(
        () =>
          kmapInstance.getView().fit(geom.getExtent(), {
            size: kmapInstance.getSize(),
            padding: zoomOptions?.padding,
            duration: zoomOptions?.duration,
          }),
        0
      )
    }
  }

  useEffect(() => {
    layer.getSource()?.addFeature(feature)
    if (zoomTo) {
      zoomToFeature(feature)
    }
    return () => {
      layer.getSource()?.removeFeature(feature)
    }
  }, [feature, kmapInstance])

  useEffect(() => {
    if (zoomTo) {
      zoomToFeature(feature)
    }
  }, [zoomTo])

  useEffect(() => {
    if (enableSnap && feature && layer && kmapInstance) {
      kmapInstance.addToSnapCollection(feature)
    }
    return () => {
      if (feature && kmapInstance) {
        kmapInstance.removeFromSnapCollection(feature)
      }
    }
  }, [enableSnap, layer, kmapInstance, feature])

  return null
}
