import { GatePassings } from '@griegconnect/krakentools-react-icons'
import { Navigation, requiresPermission } from '@griegconnect/krakentools-react-kraken-app'
import React from 'react'
import { tenantsUrlMatchPathPrefix } from '../../constants'
import { AccessDependencies, PERMISSIONS } from '../../permissions'

export const ROUTEPATH_ACTIVITIES = '/activities'

export const activitiesNavs: Navigation.Item<AccessDependencies>[] = [
  {
    name: 'Activities',
    path: tenantsUrlMatchPathPrefix + ROUTEPATH_ACTIVITIES,
    access: (d) => requiresPermission(PERMISSIONS.activities.read)(d),
    menu: {
      icon: <GatePassings />,
    },
    subNavs: [
      {
        name: 'Activities',
        path: tenantsUrlMatchPathPrefix + ROUTEPATH_ACTIVITIES,
        access: (d) => requiresPermission(PERMISSIONS.activities.read)(d),
        page: React.createElement(React.lazy(() => import('./Activities'))),
        menu: true,
        exact: true,
      },
      {
        name: 'Grouped activities',
        path: tenantsUrlMatchPathPrefix + ROUTEPATH_ACTIVITIES + '/grouped',
        access: (d) => requiresPermission(PERMISSIONS.activities.read)(d),
        page: React.createElement(React.lazy(() => import('./GroupedActivities'))),
        menu: true,
        exact: true,
      },
    ],
  },
]
