import { useSearchParams } from 'react-router-dom'

type MustBeStringUnion<T extends string> = string extends T ? never : T

type UseQueryParamsType<QpNames extends string> = {
  queryParams: Record<MustBeStringUnion<QpNames>, string | null>
  setQueryParam: (qpName: MustBeStringUnion<QpNames>, value?: string | string[] | null) => void
  /**
   * Update multiple query params at once
   * null will delete the query param
   */
  setQueryParams: (updatedQPs: Partial<Record<QpNames, string | string[] | null>>) => void
  deleteQueryParam: (qpName: MustBeStringUnion<QpNames>) => void
  clearQueryParams: () => void
  queryParamAsArray: (qpName: MustBeStringUnion<QpNames>) => string[]
  queryParamsString: string
}

/**
 * Use this hook to get, set and clear query params from the url.
 * - `queryParams`: object with all query params
 * - `setQueryParam`: function to set a query param
 * - `clearQueryParams`: function to clear all query params
 * @example
 * If you use the same QPs multiple times, you should create a custom hook:
 * ```
 * const useActivitiesQPs = () => useQueryParams<'geofenceId' | 'vesselId'>()
 * ```
 * @example
 * Then you can reuse it like this:
 * ```
 * const { queryParams, setQueryParam, clearQueryParam } = useActivitiesQPs()
 * ```
 * @example
 * Basic usage if you only need to read the query params:
 * ```
 * const { queryParams } = useQueryParams<'geofenceId' | 'vesselId'>()
 * ```
 */

export function useQueryParams<QpNames extends string>(): UseQueryParamsType<MustBeStringUnion<QpNames>> {
  const [qps, setQps] = useSearchParams()

  const queryParams: Record<MustBeStringUnion<QpNames>, string | null> = {} as Record<
    MustBeStringUnion<QpNames>,
    string | null
  >
  for (const [key, value] of qps.entries()) {
    queryParams[key as MustBeStringUnion<QpNames>] = value
  }

  const setQueryParam = (qpName: MustBeStringUnion<QpNames>, value?: string | string[] | null) => {
    if (Array.isArray(value) && value.length > 0) {
      value.forEach((val, index) => (index === 0 ? qps.set(qpName, val) : qps.append(qpName, val)))
    } else if (value && value !== undefined && value !== '') {
      qps.set(qpName, value.toString())
    } else {
      qps.delete(qpName)
    }
    setQps(qps, { replace: true })
  }

  const setQueryParams = (updatedQPs: Partial<Record<MustBeStringUnion<QpNames>, string | string[] | null>>) => {
    for (const [key, value] of Object.entries(updatedQPs)) {
      if (value === null) {
        qps.delete(key)
      } else if (Array.isArray(value) && value.length > 0) {
        value.forEach((val, index) => (index === 0 ? qps.set(key, val) : qps.append(key, val)))
      } else if (value && value !== undefined && value !== '') {
        qps.set(key, value.toString())
      } else {
        qps.delete(key)
      }
    }
    setQps(qps, { replace: true })
  }

  const clearQueryParams = () => {
    setQps({}, { replace: true })
  }

  const deleteQueryParam = (qpName: MustBeStringUnion<QpNames>) => {
    qps.delete(qpName)
    setQps(qps, { replace: true })
  }

  const queryParamAsArray = (qpName: MustBeStringUnion<QpNames>) => qps.getAll(qpName)

  return {
    queryParams,
    setQueryParam,
    setQueryParams,
    clearQueryParams,
    queryParamAsArray,
    deleteQueryParam,
    queryParamsString: qps.toString(),
  }
}
