import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgMapArea = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M12 9.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />,
    <path
      fillRule="evenodd"
      d="M12 0C8.13 0 5 3.13 5 7c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7ZM7 7c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 14.21 7 9.85 7 7Z"
      clipRule="evenodd"
    />,
    <path d="M5 16h1.8l1.6 2H6v4h12v-4h-2.4l1.6-2H19a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1Z" />
  )
export default SvgMapArea
