import { AxiosInstance } from '@griegconnect/krakentools-react-kraken-app'
import { NotificationList } from './types/NotificationList'

type NotificationListsArgs = {
  signal?: AbortSignal
}

export default class NotificationListApi {
  private httpClient: AxiosInstance

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient
  }

  async notificationLists(args: NotificationListsArgs) {
    const { signal } = args
    const resp = await this.httpClient.get<NotificationList.Data[]>('notifications', { signal })
    return resp.data
  }

  async create(notificationList: NotificationList.Form) {
    return await this.httpClient.post('notifications', notificationList)
  }

  async update(id: NotificationList.Id, notificationList: NotificationList.Form) {
    return await this.httpClient.put('notifications/' + id, notificationList)
  }

  async delete(notificationListId: NotificationList.Id) {
    return await this.httpClient.delete('notifications/' + notificationListId)
  }
}
