import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgHistory = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M4.904 14.473c-1.37-2.959-.836-6.59 1.6-9.025 3.118-3.118 8.195-3.118 11.313 0 3.119 3.119 3.119 8.196 0 11.314-2.361 2.361-5.846 2.935-8.752 1.72l.233 2.197c3.407 1.009 7.257.174 9.934-2.503 3.889-3.889 3.889-10.253 0-14.142-3.89-3.889-10.254-3.889-14.143 0-3.215 3.215-3.772 8.123-1.67 11.924l-2.064 2.063 6.21.509-.553-6.165-2.108 2.108Z" />,
    <path d="M11 12.5a1 1 0 0 0 .553.894l3 1.5a1 1 0 1 0 .894-1.788L13 11.882V8a1 1 0 1 0-2 0v4.5Z" />
  )
export default SvgHistory
