import {
  ApplicationProvider,
  ITenantAccess,
  ConfigType,
  Tenant,
  Application,
  requiresAllOfPermissions,
  TenantContextWrapper,
} from '@griegconnect/krakentools-react-kraken-app'
import { LicenseInfo } from '@mui/x-license-pro'
import { createRoot } from 'react-dom/client'
import { ReactComponent as Slide1 } from './assets/Temp.svg'
import MonitorProvider from './MonitorProvider'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { PERMISSIONS } from './permissions'
import { Settings } from 'luxon'
import ApiProvider from '@app/lib/ApiProvider'

Settings.defaultLocale = navigator.language

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

LicenseInfo.setLicenseKey(
  '3c92ac2a13f79038b2fca55b538af70bTz03MzQ0MyxFPTE3MjU3NTAwMDAwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI='
)

const callback = (tenantsAccess: ITenantAccess[], config: ConfigType, appSpec: Application.Data[]): Tenant.Type[] => {
  const accessThrough = appSpec.find((appSpec) => appSpec.ref === 'shiplog')
  const access = accessThrough
    ? tenantsAccess.filter(
        (tenantAccess) =>
          accessThrough.accessThrough.some(
            (accessThroughTenant) => accessThroughTenant.ref === tenantAccess.tenant.ref
          ) &&
          // Minimum requirements to use the application. In the future this can be && or ||
          requiresAllOfPermissions([PERMISSIONS.layers.read, PERMISSIONS.maps.read])({
            activeTenant: {
              companies: [],
              permissions: tenantAccess.permissions ?? [],
              id: tenantAccess.tenant.id,
              name: tenantAccess.tenant.name,
              ref: tenantAccess.tenant.ref,
            },
          })
      )
    : []

  return access.map((tenantAccess) => ({
    companies: [], // no access for on behalf of
    id: tenantAccess.tenant.id,
    name: tenantAccess.tenant.name,
    permissions: [...(tenantAccess.permissions ?? [])],
    ref: tenantAccess.tenant.ref,
  }))
}

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <QueryClientProvider client={queryClient}>
    <ApplicationProvider
      name="Shiplog"
      tool="port"
      appRef="shiplog"
      reloadOnTenantsChange
      i18n={{ languages: ['en-GB'] }}
      onboarding={{
        disableCreateAccount: true,
        carouselElements: [
          { description: 'Monitor your port using real time vessel data', title: 'Shiplog', component: <Slide1 /> },
        ],
      }}
      filterUserAccess={{ type: 'app-spec', callback }}
    >
      <TenantContextWrapper>
        <ApiProvider>
          <MonitorProvider />
        </ApiProvider>
      </TenantContextWrapper>
    </ApplicationProvider>
  </QueryClientProvider>
)
