import React from 'react'
import { Navigation, requiresPermission } from '@griegconnect/krakentools-react-kraken-app'
import { tenantsUrlMatchPathPrefix } from '../../constants'
import { AccessDependencies, PERMISSIONS } from '../../permissions'

export const ROUTEPATH_GEOFENCES = '/geofences'
export const ROUTEPATH_GEOFENCE_NEW = ROUTEPATH_GEOFENCES + '/new'
export const ROUTEPATH_GEOFENCE_WITH_ID = ROUTEPATH_GEOFENCES + '/:geofenceId'
export const ROUTEPATH_GEOFENCES_TABLE = ROUTEPATH_GEOFENCES + '/list'

export const geofencesNavs: Navigation.Item<AccessDependencies>[] = [
  {
    name: 'Geofences',
    path: tenantsUrlMatchPathPrefix + ROUTEPATH_GEOFENCES,
    access: (d) => requiresPermission(PERMISSIONS.activities.read)(d),
    menu: false,
    subNavs: [
      {
        name: 'Geofences table',
        path: tenantsUrlMatchPathPrefix + ROUTEPATH_GEOFENCES_TABLE,
        access: (d) => requiresPermission(PERMISSIONS.activities.read)(d),
        page: React.createElement(React.lazy(() => import('./ListGeofences'))),
      },
    ],
  },
]
