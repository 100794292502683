import { useRecoilValue } from 'recoil'
import { useMapContext } from '..'
import { visibleVesselsAtom } from '../atoms/liveViewAtoms'

export const useVisibleVessels = () => {
  const { mapIdentifierSlug } = useMapContext()
  const visibleVessels = useRecoilValue(visibleVesselsAtom(mapIdentifierSlug))

  return visibleVessels
}
